<template>
  <div class="home-wrapper">
    <div class="user-wrapper flex align-center">
      <van-image
          round
          width="1.5rem"
          height="1.5rem"
         :src="userInfo.avatar || defaultAvatar"
      />
      <div class="white f16 username">
        <span>{{userInfo.nick_name}}</span>
      </div>
    </div>
    <div class="mask">
      <div class="input-container">
        <div class="user-profile">
          <van-image
              round
              width="2rem"
              height="2rem"
              :src="userInfo.avatar || defaultAvatar"
          />
        </div>
        <div class="user-name">
          <h3>{{userInfo.nick_name}}</h3>
          <h3>欢迎查看工资条</h3>
        </div>
<!--        // this.inputType =  this.isPassword?'text':'password'-->
        <div class="input-wrapper">
          <input :type="inputType"
                 v-model="phone"
                 maxlength="6"
                 :disabled="loading"
                 placeholder="请输入绑定手机号后六位"
                 >
          <i class="iconfont"
             :class="{'icon-mimayincang':isPassword,'icon-mimaxianshi':!isPassword}"
             @click="changeType"></i>
          <p class="err-msg">{{ errMsg }}</p>
          <van-button color="#598AE4"
                      @click="verifyPhone"
                      loading-text="加载中..."
                      :loading="loading"
                      :disabled="!(phone.toString().length===6)"
                      class="btn" block>查看工资条
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5'
import {lookSalary} from '../../server/user'
import {storage} from "../../unit/units";
import {mapState} from 'vuex'
export default {
  name: "index",
  data() {
    return {
      inputType: 'password',
      phone: '',
      isShowErr:false,
      // errMsg: '验证错误，请输入正确手机6位数字',
      errMsg: '',
      loading: false,// 加载状态
      showSelectTime:false,
      isPassword:true
    }
  },
  created() {
  },
  computed: {
    ...mapState(['userInfo','defaultAvatar'])
  },
  methods: {
    // 验证手机密码
    verifyPhone() {
      this.loading = true
      let sendData ={
        salaryPassword:md5(this.phone)
      }
      lookSalary(sendData).then(res=>{
        if(res.success){
            storage.set('USERSIGN',res.data)
            this.$router.push({
              path:'/salary-list'
            })
        }else {
          this.errMsg = res.msg
          this.loading= false
        }
      }).catch(()=>{
            this.loading= false
      })
    },
    /**
     * 更改显示方式
     */
    changeType () {
        this.isPassword = !this.isPassword
        this.inputType =  this.isPassword?'password':'text'
    }
  },

}
</script>

<style scoped lang="scss">
@keyframes move-top {
  0% {
    bottom: -90%;
  }
  100% {
    bottom: 0;
  }
}

.home-wrapper {
  min-height: 100vh;
  background: linear-gradient(#487ee2, rgb(243, 243, 248));
  position: relative;
  .user-wrapper {
    padding: 20px 20px 0 20px;
  }
  .mask {
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    height: 100%;
    background: rgba(0, 0, 0, .3);

    .input-container {
      border-radius: 30px 30px 0 0;
      position: absolute;
      height: 80%;
      width: 100%;
      bottom: -90%;
      background: white;
      animation: move-top .5s linear forwards;
      .user-name {
        margin-top: 1.5rem;
        text-align: center;
        margin-bottom: 1rem;

        h3 {
          margin-bottom: 10px;
        }
      }

      .user-profile {
        width: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        top: -1rem;
        justify-content: center;
      }

      .input-wrapper {
        position: relative;
        margin: 0 40px;
        input {
          width: 100%;
          font-size: 18px;
          padding: 6px;
          border: solid 1px rgba(0, 0, 0, .5);
          border-radius: 6px;
          display: inline-block;
          box-sizing: border-box;
          line-height: 26px;
          &:hover {
            border: solid 1px rgba(89, 138, 288, .8);
          }
        }

        .err-msg {
          height: 1rem;
          line-height: .8rem;
          text-align: left;
          font-size: 8px;
          color: red;
        }

        .btn {
          border-radius: 8px;
        }
        .iconfont{
          position: absolute;
          right:10px;
          top: 8px;
          padding: 4px;
        }
      }
    }
  }
  .username{
    margin-left: 10px;
  }
}
</style>
